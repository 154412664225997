/*
 * @Author       : suzhen
 * @Email        : suzhen@dianzhong.com
 * @Date         : 2022-09-19 11:42:43
 * @LastEditors: suzhen@dainzhong.com
 * @LastEditTime: 2024-10-21 14:32:21
 * @Description  : 接口请求封装
 */
import axios from "axios";
import { getUserId, getStorage, removeUserId } from '@/utils/cookie.js'
import { getAppId, os, version, h5Log } from '@/utils'
import { Toast } from 'vant'
axios.defaults.baseURL = '/api/'

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/json"
//设置超时
axios.defaults.timeout = 10000

axios.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error)
  }
);

axios.interceptors.response.use(
  response => {
    if (response.status == 200) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    console.log(error)
  }
);

function headerComputed () {
  let setQuery = getStorage('readQuery', true)|| {}
  let headers = {
    'Content-Type': 'application/json; charset=utf-8',
    'domain': '19',
    'userId': getUserId() || window.sessionStorage.getItem('payid') || '',
    'pname': 'com.dz.h5video',
    'utdid': getStorage('utdid'),
    'oid': getStorage('hoid'),
    'os': os(),
    'appid': getAppId(),
    'channelCode': setQuery.channelId || '',
    'referral_id': setQuery.referral_id || '',
    'from': setQuery.from || '',
    'path': location.host || '',
    'mark': setQuery.mark || '',
    'pushId': setQuery.push_id || '',
    'pushIdx': setQuery.push_idx || '',
    'pushTime': setQuery.push_time || '',
    'ver': version
  }
  return headers
}

console.log('【重点关注】h5公众号短剧 版本：', version)

export default {
  post(url, data={}) {
    return new Promise((resolve, reject) => {
      // h5Log('request', 'start', 'event', {url, data, headers: headerComputed()})
      axios({
        method: 'POST',
        headers: headerComputed(),
        url,
        data: JSON.stringify(data),
      })
        .then(res => {
          if(res && res.data.retCode == 0) {
            resolve(res.data)
          } else {
            // 统一处理错误类型的码
            Toast(res && res.data && res.data.retMsg || '未知错误')
            if(res.data.retCode == 6) {
              removeUserId()
              window.location.replace(location.origin)
            }
            reject(res && res.data || {})
            h5Log('request', 'fail_data', 'event', {url, ...data, ...res.data, ...headerComputed()})
          }
        })
        .catch(err => {
          reject(err)
          h5Log('request', 'fail_server', 'event', {url,...data, err})
        })
    })
  },

  get(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        headers: headerComputed(),
        url,
        params: data,
      }).then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
